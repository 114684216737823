<template>
    <div>
        <CCard>
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="userFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { createNewPatient } from '@/services/patients';
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
    name: "addPatient",
    components: { AbstractForm },
    data(){
      return{
        title: "Añadir un nuevo paciente",
        id: null,
        userFields: {
            username: null,
            name: null,
            password: null,
            repeatPassword: null,
            surname: null,
            email: null,
            birthdate: null,
            gender: null,
            description: null,
            weight: null,
            height: null
        },
        formContent: {
            buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Crear',
                    color: 'success',
                    event: 'add',
                    style: 'margin: 10px',
                    active: !this.$store.state.isAdmin
                },
            ],
            rows: [
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'name',
                                label: 'Nombre',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'surname',
                                label: 'Apellidos',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'username',
                                label: 'Nombre de usuario',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'password',
                                label: 'Contraseña',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'repeatPassword',
                                label: 'Confirmar contraseña',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'email',
                                label: 'Email',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'date',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'date',
                                value: '',
                                key: 'birthdate',
                                label: 'Fecha de nacimiento',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'select',
                                value: '',
                                key: 'gender',
                                label: 'Género',
                                maxLength: 30,
                                options:
                                    [
                                        { name: 'Hombre', value: "male" },
                                        { name: 'Mujer', value: "female" },
                                        { name: 'Otros', value: "other" }
                                    ],
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'textArea',
                                value: '',
                                key: 'description',
                                label: 'Descripcion',
                                maxLength: 1000,
                            },
                        },
                    ]
                },
                {
                    style: [],
                    class: [],
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'weight',
                                label: 'Peso',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'height',
                                label: 'Altura',
                                maxLength: 30,
                            },
                        },
                    ]
                },
            ],
        },
      }
    },
    created() { },
    methods: {
        onEmitMethods(event, dataPatient) {
            switch (event) {
                case 'add': this.addPatient(dataPatient); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
          * Método para mostrar y ocultar las patologías
        */
        togglePathologySection() {
            const pathologyToShow = event.target.id
            const currentPathologySection = `[data-type=${pathologyToShow}]`
            const section = document.querySelector(currentPathologySection)

            if (section.style.display == "" || section.style.display == "none") {
                document.getElementById(`${pathologyToShow}`).innerHTML = "Ocultar";
                section.style.display = "block";
            } else {
                document.getElementById(`${pathologyToShow}`).innerHTML = "Mostrar";
                section.style.display = "none";
            }
        },
        /**
         * Método para validar los inputs del formulario
         */
        checkInputs(dataPatient) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const numericRegex = /^[0-9]+$/; // Expresión regular para validar números enteros
            const validationRules = [
                { field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'surname', message: 'Introduzca el apellido (máximo de 20 caracteres).', maxLength: 20 },
                { field: 'username', message: 'Introduzca el nombre de usuario (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'username', message: 'El nombre de usuario no debe contener espacios', noSpaces: true },
                { field: 'password', message: 'La contraseña debe tener mínimo 8 caracteres (máximo de 30 caracteres).', minLength: 8, maxLength: 30 },
                { field: 'repeatPassword', message: 'Las contraseñas deben ser iguales.', equalField: 'password' },
                { field: 'email', message: 'Introduzca un email válido.', regex: emailRegex },
                { field: 'birthdate', message: 'Introduzca la fecha de nacimiento.' },
                { field: 'gender', message: 'Seleccione un género.', value: '' },
                { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
                { field: 'weight', message: 'Introduzca el peso en kilogramos (sólo valores enteros).', regex: numericRegex, minValue: 0 },
                { field: 'height', message: 'Introduzca la altura en centímetros (sólo valores enteros).', regex: numericRegex, minValue: 0 },
            ];
            for (const rule of validationRules) {
                const value = dataPatient[rule.field];
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataPatient[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder crear un nuevo paciente
         */
        addPatient(dataPatient) {

            if (this.checkInputs(dataPatient) == false) {
                return;
            }
            createNewPatient(dataPatient)
                .then(() => {
                    this.$router.push("/patient");
                }).catch((error) => { 
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/patient");
        },
    },
};
</script>
<style>
.patient-pathologies,
.patient-challenges {
    padding-top: 10px;
}

.card-header .button-container {
    float: right;
}

.card-header p {
    display: inline;
}

#modalPathology .modal-body,
#modalChallenges .modal-body {
    padding-top: 30px;
}

.patient-pathologies h5 span,
.patient-pathologies h6 span {
    font-weight: 400;
}

.patient-pathologies ul {
    position: relative;
}

.patient-pathologies ul li {
    padding: 4px 0;
}

.patient-pathologies strong {
    font-weight: 500;
    padding-right: 3px;
}

.patient-pathologies .delete-button {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 3;
}

.patient-pathologies .toggle-pathology-button {
    position: absolute;
    top: 12px;
    right: 70px;
    z-index: 3;
}

.patient-pathologies .pathology-box {
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #f1f1f1;
    border-color: #d8dbe0;
    padding: 20px;
    margin-bottom: 20px;
    padding-bottom: 0;
    position: relative;
}

.patient-pathologies .pathology-box .pathology-inner-box {
    display: none;
}

.patient-pathologies .pathology-box h5 {
    padding: 10px;
    padding-top: 0;
}

.patient-pathologies .related-advice {
    padding: 0 20px;
}

@media (max-width: 768px) {
    .patient-pathologies .pathology-box {
        padding: 20px 0;
    }

    .patient-challenges>div {
        margin-bottom: 10px;
    }

    .patient-pathologies .pathology-box .delete-button,
    .patient-pathologies .pathology-box .toggle-pathology-button {
        position: static;
        margin-left: 10px;
        margin-bottom: 14px;
        width: 44%;
        display: inline;
        text-align: center;
    }

    .card-header .button-container {
        float: left;
    }

    .modal-body .form-group {
        margin-top: 10px;
    }
}
</style>